import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/zooty-logo.svg"

import DesktopStyles from "./desktop-nav.module.scss"

const DesktopNav = () => (
  // TODO: Add background to nav on scroll (or intersection observer?)
  <nav className={DesktopStyles.nav}>
    <div>
      <Link to="/" activeClassName={DesktopStyles.active}>
        Home
      </Link>
     
      <a
      href="https://labelmeathreat.com"
      >
      L.M.a.T Shop
      </a>
      <a
      href="https://look-good-feel-good.webflow.io"
      >
        Look Good Feel Good
      </a>
    </div>

    <Link to="/" className={DesktopStyles.logo}>
      <img src={Logo} alt="Zooty the barber logo" />
    </Link>

    <div>
      
      <Link to="/about/" activeClassName={DesktopStyles.active}>
        About
      </Link>
      <a
      className={DesktopStyles.book}
      href="https://squareup.com/appointments/book/EJCF1CF5DXN4W/zooty-at-procutz-sports-barbershop-lewes-de"
      >
      Book Now
      </a>
    </div>
  </nav>
)

export default DesktopNav
