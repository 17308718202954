import React from "react"
import { Link } from "gatsby"
import { FaHome, FaInfoCircle, FaCalendarAlt, FaMoneyBill, FaBook } from "react-icons/fa"
import { TiBusinessCard, TiImageOutline, TiShoppingCart } from "react-icons/ti";

import NavStyles from "./mobile-nav.module.scss"

const MobileNav = () => (
  <nav className={NavStyles.nav}>
    <Link to="/" className={NavStyles.link} activeClassName={NavStyles.active}>
      <FaHome />
    </Link>
    <Link
    >
      <a
      href="https://squareup.com/appointments/book/EJCF1CF5DXN4W/zooty-at-procutz-sports-barbershop-lewes-de"
    >
     <FaCalendarAlt />
    </a>
    </Link>
    <Link
    className={NavStyles.link}
    activeClassName={NavStyles.active}
    >
      <a
      href="https://labelmeathreat.com"
      >
      <TiShoppingCart />
    </a>
    </Link>
    <Link
    className={NavStyles.link}
    activeClassName={NavStyles.active}
    >
      <a
      href="https://look-good-feel-good.webflow.io"
      >
      <TiBusinessCard />
    </a>
    </Link>
   
    {/* <Link
      to="/about/"
      className='mobile-nav-module--link--2W2w-'
      activeClassName={NavStyles.active}
    >
      <FaInfoCircle />
    </Link> */}
  </nav>
)

export default MobileNav
