import React, {useState} from "react"
import MobileNav from "../navbar/mobile-nav"
import DesktopNav from "../navbar/desktop-nav"
import "./layout.scss"

class Layout extends React.Component{
  render(){
    return (
      <>
        <DesktopNav />        
          <>          
            <div className={`desktop-layout`}>
              {(
                <>
                  <div className={'desktop-children'}>    
                    {this.props.children}
                  </div>
                </>
              )} 
            </div>
          </> 
       <MobileNav />
       </>
    )
  }
}

export default Layout
