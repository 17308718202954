import React from "react"
import Layout from "../components/layout/layout"
import Gallery from "../components/gallery/gallery"

const GalleryPage = () => (
  <Layout>
    <Gallery />
  </Layout>
)

export default GalleryPage
